export default {
    SET_ITEM: "SET_ITEM",
    SET_ADD_CHAT_MESSAGE: "SET_ADD_CHAT_MESSAGE",
    SET_PREVIOUS_CHAT_LIST: "SET_PREVIOUS_CHAT_LIST",
    ADD_CHAT_ITEM_TO_PREVIOUS_CHAT_LIST: "ADD_CHAT_ITEM_TO_PREVIOUS_CHAT_LIST",
    REMOVE_PREVIOUS_CHAT: "REMOVE_PREVIOUS_CHAT",
    UPDATE_CHAT_ITEM_TITLE: "UPDATE_CHAT_ITEM_TITLE",
    SET_CHAT_MESSAGE_RATING: "SET_CHAT_MESSAGE_RATING",
    LOAD_MORE_MESSAGES: "LOAD_MORE_MESSAGES",
    LOAD_MORE_CHAT_LIST: "LOAD_MORE_CHAT_LIST"
};
