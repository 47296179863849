import axios from "@/plugins/axios";
export const apiLoadFaq = async ({docId}) => {
    try {
        let options = {};
        let params = {};
        const apiEndPoint = 'getFaqData';
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        if(typeof(docId) != 'undefined' && docId != null && docId != '') {
            console.log('docId', docId);
            params.append('docId', docId);
        }
        console.log('params', params);
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if(response.data) {
            if (response.data.status === 'ok') {
                let faqResponse = response.data;
                return faqResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch(e) {
        console.log('There has been a problem with your fetch operation: ' + e.message);
    }
    return "";
}