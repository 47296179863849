import axios from "@/plugins/axios";

export const apiSendMessageToAI = async ({docId, itemId, userId, chatId, resId, pubId, subId, message}) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();

        if(typeof(docId) != 'undefined' && docId != null && docId != '') {
            params.append('docId', docId);
        }
        if(typeof(itemId) != 'undefined' && itemId != null && itemId != '') {
            params.append('itemId', itemId);
        }
        if(typeof(userId) != 'undefined' && userId != null && userId != '') {
            params.append('userId', userId);
        }
        if(typeof(chatId) != 'undefined' && chatId != null && chatId != '') {
            params.append('chatId', chatId);
        }
        if(typeof(resId) != 'undefined' && resId != null && resId != '') {
            params.append('resId', resId);
        }
        if(typeof(pubId) != 'undefined' && pubId != null && pubId != '') {
            params.append('pubId', pubId);
        }
        if(typeof(message) != 'undefined' && message != null && message != '') {
            params.append('message', message);
        }
        if(typeof(subId) != 'undefined' && subId != null && subId != '') {
            params.append('subId', subId);
        }
        
        
        const apiEndPoint = 'query';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}


export const apiLoadPreviousChatList = async ({docId, itemId, userId, subId, resId, pubId, lastChatId}) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();

        if(typeof(docId) != 'undefined' && docId != null && docId != '') {
            params.append('docId', docId);
        }
        if(typeof(itemId) != 'undefined' && itemId != null && itemId != '') {
            params.append('itemId', itemId);
        }
        if(typeof(userId) != 'undefined' && userId != null && userId != '') {
            params.append('userId', userId);
        }
        if(typeof(subId) != 'undefined' && subId != null && subId != '') {
            params.append('subId', subId);
        }
        if(typeof(resId) != 'undefined' && resId != null && resId != '') {
            params.append('resId', resId);
        }
        if(typeof(pubId) != 'undefined' && pubId != null && pubId != '') {
            params.append('pubId', pubId);
        }
        if(typeof(pubId) != 'undefined' && lastChatId != null && lastChatId != '') {
            params.append('lastChatId', lastChatId);
        }
        const apiEndPoint = 'getPreviousChatList';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your fetch operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your fetch operation:' + e.message);
    }
    return [];
}

export const apiDeleteChatHistory = async (chatId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        const apiEndPoint = 'deleteChatHistory';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        
        if (response.data) {
            if (response.data.status === 'ok') {
                // return  response.data.data;
                return true;
            } else {
                console.log('There has been a problem with your delete operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your delete operation:' + e.message);
    }
    return false;
}

export const apiGenerateChatTitle = async (chatId) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        const apiEndPoint = 'generateChatTitle';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options
        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your delete operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your delete operation:' + e.message);
    }
    return false;
}

export const apiRateChatMessage = async (messageId, rating) => {
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('messageId', messageId);
        params.append('rating', rating);
        const apiEndPoint = 'rateChatMessage';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        
        if (response.data) {
            if (response.data.status === 'ok') {

                return true;
            } else {
                console.log('There has been a problem with your rating chat message operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your rating chat message operation:' + e.message);
    }
    return false;
}

export const apiloadMoreMessages = async (chatId, lastMessageId) => { 
    try {
        let options = {};
        let params = {};
        options = {headers: {'Content-Type': 'application/x-www-form-urlencoded'}};
        params = new URLSearchParams();
        params.append('chatId', chatId);
        params.append('lastMessageId', lastMessageId);
        const apiEndPoint = 'loadMoreMessages';
        const response = await axios.post({
            endpoint: '/management/services.v1.openaiajax/' + apiEndPoint,
            params: params,
            options: options

        });
        if (response.data) {
            if (response.data.status === 'ok') {
                let aiResponse = response.data;
                return aiResponse.data;
            } else {
                console.log('There has been a problem with your load more messages operation.');
            }
        }
    } catch (e) {
        console.log('There has been a problem with your load more messages operation:' + e.message);
    }
    return [];
}