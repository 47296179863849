import Mutation from './mutationTypes';

export default {
    [Mutation.SET_ITEM]: (state, { field, value }) => {
        state[field] = value;
    },    
    [Mutation.SET_ADD_CHAT_MESSAGE]: (state, { messageId, role, content, citations }) => {
        state['chatMessages'].push({ id: messageId, role, content, citations, rating: '' });
    },
    [Mutation.LOAD_MORE_MESSAGES]: (state, messages) => {
        state['chatMessages'] = messages.concat(state['chatMessages']);
    },
    [Mutation.SET_CHAT_MESSAGE_RATING]: (state, { messageId, rating }) => {
        state['chatMessages'].forEach((item, index) => {
            if(item.id == messageId) {
                state['chatMessages'][index]['rating'] = rating;
            }
        });
    },
    [Mutation.SET_PREVIOUS_CHAT_LIST]: (state, { id, previousChatList, type}) => {
        if(typeof(state['previousChatList'][type]) == 'undefined') {
            state['previousChatList'][type] = {};
        }
        state['previousChatList'][type][id] = previousChatList;
    },
    [Mutation.LOAD_MORE_CHAT_LIST]: (state, { id, previousChatList, type}) => {
        if(typeof(state['previousChatList'][type]) == 'undefined') {
            state['previousChatList'][type] = {};
            state['previousChatList'][type][id] =[];
        }
        state['previousChatList'][type][id] = state['previousChatList'][type][id].concat(previousChatList);
    },
    [Mutation.ADD_CHAT_ITEM_TO_PREVIOUS_CHAT_LIST]: (state, {id, type, chatItem}) => {
        if(typeof(state['previousChatList'][type]) == 'undefined') {
            state['previousChatList'][type] = {};
        }
        if(typeof(state['previousChatList'][type][id]) == 'undefined') {
            state['previousChatList'][type][id] = [];
        }
        state['previousChatList'][type][id].unshift(chatItem);
    },
    [Mutation.REMOVE_PREVIOUS_CHAT]: (state, {id, type, chatId }) => {
        if(typeof(state['previousChatList'][type]) != 'undefined' && typeof(state['previousChatList'][type][id]) != 'undefined') {
            let chatHistories = [];
            state['previousChatList'][type][id].forEach(item => {
                if(item.id != chatId) {
                    chatHistories.push(item);
                }
            });
            state['previousChatList'][type][id] = chatHistories;
        }
    },
    [Mutation.UPDATE_CHAT_ITEM_TITLE]: (state, { itemId, type, chatId, title }) => {
        if(typeof(state['previousChatList'][type]) != 'undefined' && typeof(state['previousChatList'][type][itemId]) != 'undefined') {
            let chatHistories = [];
            state['previousChatList'][type][itemId].forEach(item => {
                if(item.id == chatId) {
                    item.title = title;
                } 
                chatHistories.push(item);
            });
            state['previousChatList'][type][itemId] = chatHistories;
        }
    }
}