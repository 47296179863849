import Mutation from './mutationTypes';
import { apiLoadFaq } from "@/components/AIFaq/api";

export default {
  setItem: ({ commit }, { field, value }) => {
    commit(Mutation.SET_ITEM, { field, value });
  },
  initFaq: async ({ commit, dispatch }, docId) => {
    try {
      commit(Mutation.SET_ITEM, {field: 'loading', value: true});
      commit(Mutation.SET_ITEM, { field: 'faqActive', value: true });
      const faqData = await apiLoadFaq(docId);
      const parsedFaqData = faqData ? parseFaqData(faqData) : [];

      if (parsedFaqData.length > 0) {
        commit(Mutation.SET_ITEM, { field: 'faqData', value: parsedFaqData });
        commit(Mutation.SET_ITEM, {field: 'loading', value: false});
      } else {
        commit(Mutation.SET_ITEM, { field: 'faqActive', value: false });
        commit(Mutation.SET_ITEM, {field: 'loading', value: true});
        commit(Mutation.SET_ITEM, { field: 'faqData', value: [] });
      }
    //   commit(Mutation.SET_ITEM, {field: 'loading', value: false});
      dispatch('loadFaq', parsedFaqData);
    } catch (error) {
      commit(Mutation.SET_ITEM, { field: 'faqActive', value: false });
      commit(Mutation.SET_ITEM, { field: 'faqData', value: [] });
      console.log(error);
    }
  },


  closeFaq:  ({ commit }) => {
    commit(Mutation.SET_ITEM, {field: 'faqActive', value: false});
},
};

const parseFaqData = (faqData) => {
    const faqArray = faqData.split('\n\n');
    return faqArray.map(faq => {
      let [question, answer] = faq.split('\n');
      question = question.replace(/^Q\d+:\s*/, '').trim();
      answer = answer.replace(/^A\d+:\s*/, '').trim();

      return { question, answer };
    });
  };
