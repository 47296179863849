export default () => ({
    toc1: [],
    toc2: [],
    promptOptions: [],
    selectedPromptId: 0,
    diffSummary: false,
    diffSummaryId: 0,
    selectedRating: -1,
    updateRatingInProgress: false,
    itemId1: '',
    itemId2: '',
    itemName1: '',
    itemName2: '',
    language: '',
    docName: '',
    userId: '',
    docId: '',
    loading: false,
    errMessage: '',
    showError: false,
    showTOCError: false,
    showCompareWindow: false,
    showTOCSelection: false,
    showCompareSummary: false,
    toc1Selection: {},
    toc2Selection: {},
    abortController: {},
    processAborted: false,
});